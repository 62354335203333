"use client";

import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import NavbarComponent from "@/components/navbar";
import Footer from "@/components/footer";
import { setGlobalTheme } from "@/utils";
import OnlyClient from "@/utils/OnlyClient";
import { ThemeProvider, useTheme } from "@/utils/ThemeContext";
import PriceTicker from "@/components/price-ticker";
import { fetchGpuPrices, GpuPriceDbData } from "@/services/gpu-prices";

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useTheme();

  useEffect(() => {
    setGlobalTheme(theme);
  }, [theme]);

  return <>{children}</>;
};

export default function Template({ children }: { children: React.ReactNode }) {
  const [gpuPrices, setGpuPrices] = useState<
    | {
        vendor: string;
        name: string;
        averagePrice: number;
        availableNum: number;
      }[]
    | null
  >(null);

  useEffect(() => {
    const getPrices = async () => {
      try {
        const response = await fetchGpuPrices();
        const prices = await response.json();

        // Combine objects with the same name, filter out `dev-spheron` regions
        const aggregatedPrices = prices
          .filter((item: GpuPriceDbData) => item.region !== "dev-spheron")
          .reduce((acc: any, curr: any) => {
            const existing = acc.find((item: any) => item.name === curr.name);
            if (existing) {
              // Combine averagePrice and num
              existing.averagePrice =
                (existing.averagePrice * existing.num +
                  curr.averagePrice * curr.num) /
                (existing.num + curr.num);
              existing.num += curr.num;
              existing.availableNum += curr.availableNum;
            } else {
              acc.push({ ...curr });
            }
            return acc;
          }, []);
        setGpuPrices(aggregatedPrices);
      } catch (error) {
        console.error("Failed to fetch prices:", error);
      }
    };

    getPrices();

    const intervalDuration = 1 * 60 * 60 * 1000; // Refresh every 1 hour
    const intervalId = setInterval(() => {
      getPrices();
    }, intervalDuration);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <ThemeProvider>
        <ThemeWrapper>
          <ToastContainer newestOnTop limit={2} />
          <nav className="sticky top-0 z-[99]">
            <NavbarComponent />
          </nav>
          <>
            <PriceTicker gpuPrices={gpuPrices} />
            <main className="lg:px-14 px-4 min-h-screen max-w-[1400px] mx-auto">
              {children}
            </main>
          </>
          <footer className="mt-[100px]">
            <OnlyClient>
              <Footer />
            </OnlyClient>
          </footer>
        </ThemeWrapper>
      </ThemeProvider>
    </>
  );
}
