import { BASE_URL } from "@/config";

export interface GpuPriceDbData {
  region: string;
  name: string;
  averagePrice: number;
  availableNum: number;
  num: number;
}

export const fetchGpuPrices = async () => {
  const response = await fetch(`${BASE_URL}/api/gpu-prices`, {
    method: "GET",
  });
  return response;
};

export const saveGpuPrices = async ({
  name,
  vendor,
  price,
  token,
  availableNum,
}: {
  name: string;
  vendor: string;
  price: string;
  token: string;
  availableNum: Number;
}) => {
  if (!name || !vendor || !price || !token) return;
  const response = await fetch(`${BASE_URL}/api/gpu-prices`, {
    method: "POST",
    body: JSON.stringify({ name, price, vendor, token, availableNum }),
  });
  return response;
};
