import { ethers, JsonRpcProvider } from "ethers";
import { SAMPLE_PROVIDER_ADDRESS, SPHERON_RPC_URL } from "@/config";
import ProviderRegistryAbi from "@/contracts/abis/ProviderRegistry.json";
import { ProviderRegistry } from "@/contracts/addresses";
import { notFound } from "next/navigation";
import { getProviderStatus, StatusResponse } from "./status";
import { getProviderRewardsPerEra, getTotalRewards } from "./rewards";
import { TrustTierMultiplier } from "@/utils";
import { getProviderVersion } from "./version";
import { decompressSpec } from "@/utils/spec";

export const getAllProviders = async (
  pageSize: number = 10,
  pageNumber: number = 1,
  searchTerm: string = ""
) => {
  try {
    const provider = new JsonRpcProvider(SPHERON_RPC_URL);
    const contractAbi = ProviderRegistryAbi;
    const contractAddress = ProviderRegistry;

    const contract = new ethers.Contract(
      contractAddress,
      contractAbi,
      provider
    );
    const responses: any[] = await contract.getAllProviders();

    // Filter out providers with localhost hostUri or no name
    const filteredResponses = responses.filter((response) =>
      // response[6] !== "localhost" && response[1] && response[1].trim() !== ""
      {
        if (response.hostUri !== "localhost") return true;
      }
    );

    // Apply search filter
    // const searchFilteredResponses = filteredResponses.filter(response =>
    //   response[1].toLowerCase().includes(searchTerm.toLowerCase()) || // name
    //   response[3].toLowerCase().includes(searchTerm.toLowerCase())    // address
    // );

    // Process all providers to get their details including total rewards
    // const allProviderDetails = await Promise.all(
    //   filteredResponses.map(async (response) => {
    //     let name, region, attrs;
    //     try {
    //       const { Name, Region, Attributes } = JSON.parse(response.spec);
    //       name = Name;
    //       region = Region;
    //       attrs = Attributes;
    //     } catch {
    //       try {
    //         const { Name, Region, Attributes } = decompressSpec(
    //           response.spec
    //         ) as any;
    //         name = Name;
    //         region = Region;
    //         attrs = Attributes;
    //       } catch {
    //         name = "";
    //         region = "";
    //         attrs = [];
    //       }
    //     }
    //     const isOnlyGateway =
    //       attrs.find((attr: any) => attr.Key === "gateway")?.Value || false;

    //     let specs: StatusResponse = {
    //       totalCPUs: 0,
    //       totalMemory: 0,
    //       totalStorage: 0,
    //       gpuInfos: [],
    //     };
    //     let version = "-";
    //     if (response.status.toString() === "2") {
    //       specs = await getProviderStatus(response.hostUri);
    //       version = await getProviderVersion(response.hostUri);
    //     }
    //     const isRegistered = Number(response.status) === 1;
    //     const perEraRewardData = await getProviderRewardsPerEra(
    //       response.walletAddress,
    //       isRegistered,
    //       TrustTierMultiplier[Number(response.tier.toString()) + 1]
    //     );
    //     // const perEraRewardData = 0;
    //     const totalRewardsData = 0;
    //     return {
    //       id: response.providerId.toString(),
    //       name,
    //       region,
    //       address: response.walletAddress,
    //       hostUri: response.hostUri,
    //       status: response.status.toString(),
    //       trust: Number(response.tier.toString()) + 1,
    //       specs,
    //       perEraRewardData,
    //       totalRewardsData,
    //       timestamp: Number(response.joinTimestamp.toString()),
    //       version,
    //       isOnlyGateway,
    //     };
    //   })
    // );

    const batchSize = 50;
    const delayMs = 1000;

    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    // Chunk the responses into batches of `batchSize`
    const batches = [];
    for (let i = 0; i < filteredResponses.length; i += batchSize) {
      batches.push(filteredResponses.slice(i, i + batchSize));
    }

    const allProviderDetails: any[] = [];

    for (const batch of batches) {
      // Process each batch in parallel
      const results = await Promise.all(
        batch.map(async (response) => {
          let name, region, attrs;
          try {
            const { Name, Region, Attributes } = JSON.parse(response.spec);
            name = Name;
            region = Region;
            attrs = Attributes;
          } catch {
            try {
              const { Name, Region, Attributes } = decompressSpec(
                response.spec
              ) as any;
              name = Name;
              region = Region;
              attrs = Attributes;
            } catch {
              name = "";
              region = "";
              attrs = [];
            }
          }
          const isOnlyGateway =
            attrs.find((attr: any) => attr.Key === "gateway")?.Value || false;

          let specs: StatusResponse = {
            totalCPUs: 0,
            totalMemory: 0,
            totalStorage: 0,
            gpuInfos: [],
          };
          let version = "-";
          if (response.status.toString() === "2") {
            specs = await getProviderStatus(response.hostUri);
            version = await getProviderVersion(response.hostUri);
          }
          const isRegistered = Number(response.status) === 1;
          const perEraRewardData = await getProviderRewardsPerEra(
            response.walletAddress,
            isRegistered,
            TrustTierMultiplier[Number(response.tier.toString()) + 1]
          );
          const totalRewardsData = await getTotalRewards(
            response.walletAddress,
            isRegistered,
            perEraRewardData
          );
          // const totalRewardsData = 0;
          return {
            id: response.providerId.toString(),
            name,
            region,
            address: response.walletAddress,
            hostUri: response.hostUri,
            status: response.status.toString(),
            trust: Number(response.tier.toString()) + 1,
            specs,
            perEraRewardData,
            totalRewardsData,
            timestamp: Number(response.joinTimestamp.toString()),
            version,
            isOnlyGateway,
          };
        })
      );

      // Append results from this batch to the overall results
      allProviderDetails.push(...results);

      // Delay before the next batch
      await delay(delayMs);
    }

    const filteredProviders = [...allProviderDetails].filter(
      (provider) =>
        provider.name.trim() !== "" &&
        provider.region !== "dev-spheron" &&
        !provider.isOnlyGateway
    );

    const sortedProviders = [...filteredProviders].sort((a, b) => {
      // Define the priority for statuses
      const statusPriority: { [key: string]: number } = { "2": 1, "1": 2, "3": 3 };
    
      // Compare statuses based on priority
      const statusDiff = (statusPriority[a.status] || 4) - (statusPriority[b.status] || 4);
      if (statusDiff !== 0) return statusDiff;
    
      // If statuses are the same, compare by totalRewardsData
      const totalRewardsDataDiff =
        Number(b.totalRewardsData.toString()) -
        Number(a.totalRewardsData.toString());
    
      if (totalRewardsDataDiff !== 0) return totalRewardsDataDiff;
    
      // If totalRewardsData is also the same, compare by perEraRewards
      return (
        Number(b.perEraRewardData.toString()) -
        Number(a.perEraRewardData.toString())
      );
    });

    const sortedProvidersWithRank = [...sortedProviders].map((item, index) => ({
      ...item,
      rank: index + 1,
    }));

    // Calculate total pages after filtering and sorting
    const totalFilteredCount = sortedProviders.length;
    // const totalPages = Math.ceil(totalFilteredCount / pageSize);

    // Calculate start and end indices for pagination
    // const startIndex = (pageNumber - 1) * pageSize;
    // const endIndex = startIndex + pageSize;

    // Slice the sorted providers array to get the current page's data
    // const paginatedProviders = sortedProviders.slice(startIndex, endIndex);

    return {
      providers: sortedProvidersWithRank,
      totalCount: totalFilteredCount,
      // pageSize,
      // pageNumber,
      // totalPages,
    };
  } catch (error) {
    console.log("Error in getting provider details -> ", error);
    notFound();
  }
};
